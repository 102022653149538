<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title class="body-1">
        Asukoht kaardil
        <v-spacer></v-spacer>
        <v-btn icon small @click="show = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <iframe
          width="100%"
          height="400"
          :src="mapUrl"
          frameborder="0"
          scrolling="no"
        >
          <a href="https://www.maps.ie/draw-radius-circle-map/">
            Google Maps Radius
          </a>
        </iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-3" @click="show = false">
          Sulge
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'VenueMapDialog',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    mapUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
